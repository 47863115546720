import { reactive, toRefs } from 'vue';
import { vsModal } from '../investments/useInvestmentsModals';

const modals = reactive({
  modalAddExamination: vsModal({
    text: {
      title: 'Dodawanie badania lekarskiego',
    },
  }),
  modalEditExamination: vsModal({
    text: {
      title: 'Edycja badania lekarskiego',
    },
  }),
  modalAddTraining: vsModal({
    text: {
      title: 'Dodawanie szkolenia BHP',
    },
  }),
  modalEditTraining: vsModal({
    text: {
      title: 'Edycja szkolenia BHP',
    },
  }),
});

export default function useCadreModals() {
  return { ...toRefs(modals) };
}
