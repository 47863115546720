import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCadreApi from '@/hooks/cadre/useCadreApi';

const { skill } = useCadreApi();

export default defineStore('cadreSkill', () => {
  const { state, fetch, fetched } = useRemoteResource('cadre.skill', skill.fetch);

  return {
    state,
    fetch,
    fetched,
  };
});
