<template>
  <div
    :class="{
      'cadre-employee-picker-option': true,
      'cadre-employee-picker-option--deleted': !!employee.deleted_at,
    }"
  >
    <avatar
      :user="employee"
      :displayUserIcon="!!employee.user_core_id"
      v-if="!noAvatar"
      :deleted="!!employee.deleted_at"
    />
    <div>
      <div class="core-user-picker-option__name">
        {{ employee.forename || '' }} {{ employee.surname || ''}}
      </div>
      <div class="core-user-picker-option__proffession">
        <slot name="selectedProfession" :id="employee.id">
          {{ employee.email }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/ui/Avatar.vue';

export default {
  components: { Avatar },
  name: 'CadreEmployeePickerOption',
  props: {
    employee: {
      type: Object,
      default: () => ({}),
    },
    noAvatar: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.cadre-employee-picker-option {
  display: flex;
  align-items: center;
  gap: 10px;

  &__proffession {
    opacity: 0.5;
  }

  &__name {
    font-weight: 500;
  }

  &--deleted {
    .core-user-picker-option__name {
      color: #fa4141;
      transition: 0.2s color ease-in-out;
    }
  }
}
</style>
