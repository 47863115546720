<template>
  <div class="cadre-employee-examination-form">
    <vs-form
      v-model="training"
      :errors="feedback"
      i18nNamespace="form.cadre.training"
    >
      <vs-form-group
        field="employee_id"
      >
        <cadre-employee-picker
          v-model="training.employee_id"
          v-if="!hideEmployeePicker && !editedEmployee"
        />
        <cadre-employee-label
          :id="training.employee_id"
          :data="editedEmployee"
          withAvatar
          target="_blank"
          v-else
        />
      </vs-form-group>

      <vs-form-group
        field="name"
      >
        <b-form-input
          :placeholder="$t('cadre.safetyTrainings.field.name')"
          v-model="training.name"
        />
      </vs-form-group>

      <vs-form-group
        field="training_date"
      >
        <vs-datepicker
          v-model="training.training_date"
        />
      </vs-form-group>

      <vs-form-group
        field="expiration_date"
      >
        <vs-datepicker
          v-model="training.expiration_date"
          reset-button
        />
      </vs-form-group>

      <vs-form-group
        field="file"
      >
        <vs-file
          v-if="!training.id"
          v-model="training.file"
        />

        <file-dialog
          v-else
          v-model="training"
          :filename="training._filename"
        />
      </vs-form-group>
    </vs-form>
  </div>
</template>

<script>
import {
  computed,
} from 'vue';
import {
  BFormInput,
} from 'bootstrap-vue';
import formatDate from '@/libs/date-formatter';
import FileDialog from '@/components/ui/file-dialog/FileDialog.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';
import VsFile from '@/components/ui/vs-file/VsFile.vue';
import CadreEmployeeLabel from '../CadreEmployeeLabel.vue';

export default {
  name: 'CadreTrainingForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
    hideEmployeePicker: {
      type: Boolean,
      default: false,
    },
    editedEmployee: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const errors = {};

    const training = computed({
      get: () => props.value,
      set: (val) => emit('input', val),
    });

    return {
      training,
      errors,
    };
  },
  computed: {
    orderedDateDwa: {
      get() {
        return formatDate.standard(this.training.ordered_at_dwa);
      },
      set(newDate) {
        this.training.value.ordered_at_dwa = newDate;
      },
    },
  },
  components: {
    BFormInput,
    FileDialog,
    VsFormGroup,
    VsDatepicker,
    VsForm,
    CadreEmployeeLabel,
    CadreEmployeePicker,
    VsFile,
  },
};
</script>
