<template>
  <vs-modal
    :modalInstance="modalAddTraining"
    @ok="addTraining"
  >
    <cadre-training-form
      v-model="fields"
      :feedback="feedback"
      :hide-employee-picker="hideEmployeePicker"
      :editedEmployee="editedEmployee"
    />
  </vs-modal>
</template>

<script>
import CadreTrainingForm from '@/components/views/cadre/ui/safety-trainings/CadreTrainingForm.vue';
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import useCadreModals from '@/hooks/cadre/useCadreModals';
import useCadreApi from '@/hooks/cadre/useCadreApi';
import showToast from '@/libs/toasts';
import { AddTrainingError, AddTrainingSuccess, AttachmentSizeError } from '@/libs/toasts/feedback/module/cadre';
import { ref, watch } from 'vue';

export default {
  name: 'CadreTrainingAdd',
  emits: ['success', 'fail'],
  props: {
    editedEmployee: {
      type: Object,
    },
  },
  setup(_, { emit }) {
    const { modalAddTraining } = useCadreModals();
    const hideEmployeePicker = ref(true);

    const {
      fields,
      feedback,
      areAllFieldsValid,
      resetFields,
      observeFieldError,
      assignFields,
      errors,
    } = useValidatedForm({
      employee_id: 'integer|required',
      name: 'required',
      training_date: 'required',
      expiration_date: '',
      file: '',
    });

    watch(() => modalAddTraining.value.visible, () => {
      if (!modalAddTraining.value.visible) {
        resetFields();
        modalAddTraining.value.data = {};
        return;
      }

      assignFields(modalAddTraining.value.data);
      if (!fields.value.employee_id) {
        hideEmployeePicker.value = false;
      }
    });

    observeFieldError('employee_id', () => {
      hideEmployeePicker.value = false;
    });

    const addTraining = async () => {
      if (!await areAllFieldsValid()) return;
      modalAddTraining.value.isLoading = true;

      const formData = new FormData();

      Object.keys(fields?.value).map((x) => {
        const name = fields?.value?.[x];
        formData.append(x, name === null ? '' : name);
        return true;
      });

      const request = useCadreApi().addSafetyTraining(formData);

      request
        .then(({ data }) => {
          resetFields();
          showToast(AddTrainingSuccess);
          modalAddTraining.value.close();
          emit('success', data);
        })
        .catch(({ response }) => {
          if (response.status === 413 || response.status === 422) {
            showToast(AttachmentSizeError);
          } else {
            showToast(AddTrainingError);
          }
          emit('fail', response);
          errors.serverSide.value = response?.data?.errors;
        })
        .finally(() => {
          modalAddTraining.value.isLoading = false;
        });
    };

    return {
      fields,
      feedback,
      addTraining,
      modalAddTraining,
      hideEmployeePicker,
    };
  },
  components: {
    CadreTrainingForm,
    VsModal,
  },
};
</script>
