var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-modal', {
    attrs: {
      "modalInstance": _vm.modalAddExamination
    },
    on: {
      "ok": _vm.addExamination
    }
  }, [_c('cadre-examination-form', {
    attrs: {
      "hide-employee-picker": _vm.hideEmployeePicker,
      "feedback": _vm.feedback,
      "editedEmployee": _vm.editedEmployee
    },
    model: {
      value: _vm.fields,
      callback: function ($$v) {
        _vm.fields = $$v;
      },
      expression: "fields"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }