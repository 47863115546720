<template>
  <div class="cadre-employee-examination-form">
    <vs-form
      v-model="examination"
      :errors="feedback"
      i18nNamespace="form.cadre.examination"
    >
      <vs-form-group
        field="medical_exam_type"
      >
        <cadre-examination-type-picker
          v-model="examination.medical_exam_type"
        />
      </vs-form-group>

      <vs-form-group
        field="employee_id"
      >
        <cadre-employee-picker
          v-model="examination.employee_id"
          placeholder="Wybierz pracownika"
          v-if="!hideEmployeePicker && !editedEmployee"
        />
        <cadre-employee-label
          :id="examination.employee_id"
          :data="editedEmployee"
          withAvatar
          target="_blank"
          v-else
        />
      </vs-form-group>

      <vs-form-group
        field="exam_date"
      >
        <vs-datepicker
          v-model="examination.exam_date"
        />
      </vs-form-group>

      <vs-form-group
        field="expiration_date"
      >
        <vs-datepicker
          v-model="examination.expiration_date"
          reset-button
        />
      </vs-form-group>

      <vs-form-group
        field="file"
      >
        <vs-file
          v-if="!examination.id"
          v-model="examination.file"
        />
        <file-dialog
          v-else
          v-model="examination"
          :filename="examination._filename"
        />
      </vs-form-group>
    </vs-form>
  </div>
</template>

<script>
import {
  computed,
} from 'vue';
import FileDialog from '@/components/ui/file-dialog/FileDialog.vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import VsFile from '@/components/ui/vs-file/VsFile.vue';
import CadreExaminationTypePicker from '@/components/module/cadre/CadreExaminationTypePicker.vue';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';
import CadreEmployeeLabel from '../CadreEmployeeLabel.vue';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
    hideEmployeePicker: {
      type: Boolean,
      default: false,
    },
    editedEmployee: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const examination = computed({
      get: () => props.value,
      set: (val) => emit('input', { ...val }),
    });

    return {
      examination,
    };
  },
  components: {
    FileDialog,
    VsForm,
    VsFormGroup,
    CadreEmployeePicker,
    VsDatepicker,
    CadreEmployeeLabel,
    VsFile,
    CadreExaminationTypePicker,
  },
};
</script>
