<template>
  <vs-modal
    :modalInstance="modalAddExamination"
    @ok="addExamination"
  >
    <cadre-examination-form
      :hide-employee-picker="hideEmployeePicker"
      :feedback="feedback"
      :editedEmployee="editedEmployee"
      v-model="fields"
    />
  </vs-modal>
</template>

<script>
import CadreExaminationForm from '@/components/views/cadre/ui/examinations/CadreExaminationForm.vue';
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useCadreModals from '@/hooks/cadre/useCadreModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import useCadreApi from '@/hooks/cadre/useCadreApi';
import showToast from '@/libs/toasts';
import {
  AddExaminationSuccess,
  AddExaminationError,
  AttachmentSizeError,
} from '@feedback/module/cadre';
import { ref, watch } from 'vue';

export default {
  name: 'CadreExaminationAdd',
  emits: ['success', 'fail'],
  props: {
    editedEmployee: {
      type: Object,
    },
  },
  setup(_, { emit }) {
    const { modalAddExamination } = useCadreModals();
    const hideEmployeePicker = ref(true);

    const {
      fields,
      feedback,
      areAllFieldsValid,
      resetFields,
      errors,
      observeFieldError,
      assignFields,
      setFieldDefaults,
    } = useValidatedForm({
      medical_exam_type: 'required',
      employee_id: 'integer|required',
      exam_date: 'required',
      expiration_date: '',
    });

    setFieldDefaults({ medical_exam_type: 'EARLY' });

    watch(() => modalAddExamination.value.visible, () => {
      if (!modalAddExamination.value.visible) {
        resetFields();
        modalAddExamination.value.data = {};
        return;
      }

      if (modalAddExamination.value.data) {
        assignFields(modalAddExamination.value.data);
        if (!fields.value.employee_id) {
          hideEmployeePicker.value = false;
        }
      }
    });

    observeFieldError('employee_id', () => {
      hideEmployeePicker.value = false;
    });

    const addExamination = async () => {
      if (!await areAllFieldsValid()) return;
      modalAddExamination.value.isLoading = true;

      const formData = new FormData();

      Object.keys(fields?.value).map((e) => {
        const name = fields?.value?.[e];
        formData.append(e, name === null ? '' : name);
        return true;
      });

      const request = useCadreApi()
        .addMedicalExamination(formData);

      request
        .then(({ data }) => {
          resetFields();
          showToast(AddExaminationSuccess);
          emit('success', data);
          modalAddExamination.value.close();
        })
        .catch(({ response }) => {
          if (response.status === 413 || response.status === 422) {
            showToast(AttachmentSizeError);
          } else {
            showToast(AddExaminationError);
          }
          errors.serverSide.value = response?.data?.errors;
          emit('fail', response);
        })
        .finally(() => { modalAddExamination.value.isLoading = false; });
    };

    return {
      fields,
      modalAddExamination,
      addExamination,
      feedback,
      hideEmployeePicker,
    };
  },
  components: {
    CadreExaminationForm,
    VsModal,
  },
};
</script>
