var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'cadre-employee-picker-option': true,
      'cadre-employee-picker-option--deleted': !!_vm.employee.deleted_at
    }
  }, [!_vm.noAvatar ? _c('avatar', {
    attrs: {
      "user": _vm.employee,
      "displayUserIcon": !!_vm.employee.user_core_id,
      "deleted": !!_vm.employee.deleted_at
    }
  }) : _vm._e(), _c('div', [_c('div', {
    staticClass: "core-user-picker-option__name"
  }, [_vm._v(" " + _vm._s(_vm.employee.forename || '') + " " + _vm._s(_vm.employee.surname || '') + " ")]), _c('div', {
    staticClass: "core-user-picker-option__proffession"
  }, [_vm._t("selectedProfession", function () {
    return [_vm._v(" " + _vm._s(_vm.employee.email) + " ")];
  }, {
    "id": _vm.employee.id
  })], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }