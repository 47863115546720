<template>
  <router-link
    :to="{ name: 'cadre.employee.details', params: { id: employee.id } }"
    class="cadre-employee-label"
    :class="{ 'deleted': !!employee.deleted_at }"
    :target="target"
    >
    <div class="select-option d-flex align-items-center">
      <avatar
        :avatarDeleteButton="removable"
        v-if="nameOnly || withAvatar"
        :displayUserIcon="!!employee.user_core_id"
        :user="employee"
        @delete="$emit('delete')"
        :deleted="!!employee.deleted_at"
      />
      <div class="details">
        <div class="name font-weight-bold">
          {{ employee.forename || '' }} {{ employee.surname || ''}}
        </div>
        <div
          class="proffession"
          v-if="(!nameOnly && !noProfession) || (noProfession && this.$slots['subtitle'])"
        >
          <slot name="subtitle">
            {{ employee.email }}
          </slot>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import Avatar from '@/components/ui/Avatar.vue';
import { computed, ref, watch } from 'vue';
import store from '@/store';

export default {
  name: 'CadreEmployeeLabel',
  props: {
    nameOnly: {
      type: Boolean,
      default: false,
    },
    withAvatar: {
      type: Boolean,
      default: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
    },
    data: {
      type: Object,
      default: null,
    },
    noProfession: {
      type: Boolean,
      default: false,
    },
    forceUpdate: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
    },
  },
  setup(props) {
    const local = ref(null);
    const employee = computed(() => local.value ?? props.data ?? {});

    const updateEmployee = () => {
      const id = props.id ?? props.data?.id;
      if (!props.forceUpdate && props.data) return;
      if (!props.forceUpdate && !props.id) return;
      if (!id) return;
      if (local.value) return;

      store.dispatch('cadre/employees/fetchOne', {
        employeeId: id,
        force: true,
        setCurrent: false,
      })
        .then(({ data }) => {
          local.value = data?.data ?? {};
        });
    };

    updateEmployee();

    watch(() => [props.id, props.data], updateEmployee);

    return {
      employee,
    };
  },
  components: { Avatar },
};
</script>

<style lang="sass" scoped>
.cadre-employee-label

  &.deleted
    color: #fa4141
    transition: 0.2s color ease-in-out

    &:hover
      color: rgba(#fa4141, 0.5)

  .select-option
    gap: 10px

  .proffession
    color: var(--vs-color)
</style>
