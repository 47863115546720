<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useCadreExaminationStore from '@/stores/module/cadre/useCadreExaminationStore';
import i18n from '@/libs/i18n';

const getI18nTypeName = (name) => {
  const translatedType = i18n.t(`cadre.medicalExams.type.${name}`) ?? '';
  return `Badanie ${translatedType.toLowerCase()}`;
};

export default Vue.component('CadreEmploymentFormPicker', {
  emits,
  setup(_, ctx) {
    const store = useCadreExaminationStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz typ badania z listy',
      reduce: (option) => option,
      options: store.typesRemoteResource.state,
      ...ctx.attrs,
    }, {
      option: ({ option }) => getI18nTypeName(option.name),
      'selected-option': ({ option }) => getI18nTypeName(option.name),
    }));
  },
  components: { VsPickerVue },
});
</script>
