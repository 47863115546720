import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCadreApi from '@/hooks/cadre/useCadreApi';

const { examination } = useCadreApi();

export default defineStore('cadreExamination', () => {
  const typesRemoteResource = useRemoteResource('cadre.examination-type',
    examination.fetchTypes,
    (data) => Object.values(data.data));

  return {
    typesRemoteResource,
  };
});
