var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cadre-employee-examination-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.feedback,
      "i18nNamespace": "form.cadre.training"
    },
    model: {
      value: _vm.training,
      callback: function ($$v) {
        _vm.training = $$v;
      },
      expression: "training"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "employee_id"
    }
  }, [!_vm.hideEmployeePicker && !_vm.editedEmployee ? _c('cadre-employee-picker', {
    model: {
      value: _vm.training.employee_id,
      callback: function ($$v) {
        _vm.$set(_vm.training, "employee_id", $$v);
      },
      expression: "training.employee_id"
    }
  }) : _c('cadre-employee-label', {
    attrs: {
      "id": _vm.training.employee_id,
      "data": _vm.editedEmployee,
      "withAvatar": "",
      "target": "_blank"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('cadre.safetyTrainings.field.name')
    },
    model: {
      value: _vm.training.name,
      callback: function ($$v) {
        _vm.$set(_vm.training, "name", $$v);
      },
      expression: "training.name"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "training_date"
    }
  }, [_c('vs-datepicker', {
    model: {
      value: _vm.training.training_date,
      callback: function ($$v) {
        _vm.$set(_vm.training, "training_date", $$v);
      },
      expression: "training.training_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "expiration_date"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "reset-button": ""
    },
    model: {
      value: _vm.training.expiration_date,
      callback: function ($$v) {
        _vm.$set(_vm.training, "expiration_date", $$v);
      },
      expression: "training.expiration_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "file"
    }
  }, [!_vm.training.id ? _c('vs-file', {
    model: {
      value: _vm.training.file,
      callback: function ($$v) {
        _vm.$set(_vm.training, "file", $$v);
      },
      expression: "training.file"
    }
  }) : _c('file-dialog', {
    attrs: {
      "filename": _vm.training._filename
    },
    model: {
      value: _vm.training,
      callback: function ($$v) {
        _vm.training = $$v;
      },
      expression: "training"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }