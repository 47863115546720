<template>
  <span class="branch-label">
    {{ branch.shortcut }}
  </span>
</template>

<script>
import { computed } from 'vue';
import store from '@/store';

export default {
  props: {
    id: Number,
  },
  setup(props) {
    const getBranchById = store.getters['branches/byId'];
    const branch = computed(() => getBranchById(props.id) || {});

    return { branch };
  },
  created() {
    this.$store.dispatch('branches/fetch');
  },
};
</script>

<style lang="sass" scoped>
</style>
