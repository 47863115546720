var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Zachowaj obecny",
      "active": ""
    },
    on: {
      "click": function ($event) {
        return _vm.updateValue('keep');
      }
    }
  }, [_c('b-card-text', [_vm._v(" Zachowaj obecny załącznik (" + _vm._s(_vm.filename) + ") bez zmian. ")])], 1), _c('b-tab', {
    attrs: {
      "title": "Zmień"
    },
    on: {
      "click": function ($event) {
        return _vm.updateValue('change');
      }
    }
  }, [_c('b-card-text', [_vm._v(" Wybierz plik używając poniższego przycisku. Wybrany plik zastąpi istniejący załącznik (jeżeli istnieje) "), _c('vs-file', {
    staticClass: "mt-1",
    model: {
      value: _vm.localFile,
      callback: function ($$v) {
        _vm.localFile = $$v;
      },
      expression: "localFile"
    }
  })], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Usuń"
    },
    on: {
      "click": function ($event) {
        return _vm.updateValue('delete');
      }
    }
  }, [_c('b-card-text', [_c('state-badge', {
    attrs: {
      "text": "Załączony plik zostanie na stałe usunięty z aplikacji"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }