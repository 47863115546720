<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useCadreEmployeeStore from '@/stores/module/cadre/useCadreEmployeeStore';
import CadreEmployeePickerOption from './CadreEmployeePickerOption.vue';

export default Vue.component('CadreEmployeePicker', {
  emits,
  setup(_, ctx) {
    const store = useCadreEmployeeStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz pracownika z listy',
      reduce: (option) => option.id,
      options: store.state,
      label: 'forename',
      filter: ['forename', 'surname', 'full_name', 'short_code'],
      ...ctx.attrs,
    }, {
      option: ({ option }) => h(CadreEmployeePickerOption, { props: { employee: option } }),
      'selected-option': ({ option, props }) => h(CadreEmployeePickerOption, {
        props: {
          employee: option,
          noAvatar: props.multiple,
        },
      }),
    }));
  },
  components: { VsPickerVue },
});
</script>
