var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cadre-employee-examination-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.feedback,
      "i18nNamespace": "form.cadre.examination"
    },
    model: {
      value: _vm.examination,
      callback: function ($$v) {
        _vm.examination = $$v;
      },
      expression: "examination"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "medical_exam_type"
    }
  }, [_c('cadre-examination-type-picker', {
    model: {
      value: _vm.examination.medical_exam_type,
      callback: function ($$v) {
        _vm.$set(_vm.examination, "medical_exam_type", $$v);
      },
      expression: "examination.medical_exam_type"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "employee_id"
    }
  }, [!_vm.hideEmployeePicker && !_vm.editedEmployee ? _c('cadre-employee-picker', {
    attrs: {
      "placeholder": "Wybierz pracownika"
    },
    model: {
      value: _vm.examination.employee_id,
      callback: function ($$v) {
        _vm.$set(_vm.examination, "employee_id", $$v);
      },
      expression: "examination.employee_id"
    }
  }) : _c('cadre-employee-label', {
    attrs: {
      "id": _vm.examination.employee_id,
      "data": _vm.editedEmployee,
      "withAvatar": "",
      "target": "_blank"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "exam_date"
    }
  }, [_c('vs-datepicker', {
    model: {
      value: _vm.examination.exam_date,
      callback: function ($$v) {
        _vm.$set(_vm.examination, "exam_date", $$v);
      },
      expression: "examination.exam_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "expiration_date"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "reset-button": ""
    },
    model: {
      value: _vm.examination.expiration_date,
      callback: function ($$v) {
        _vm.$set(_vm.examination, "expiration_date", $$v);
      },
      expression: "examination.expiration_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "file"
    }
  }, [!_vm.examination.id ? _c('vs-file', {
    model: {
      value: _vm.examination.file,
      callback: function ($$v) {
        _vm.$set(_vm.examination, "file", $$v);
      },
      expression: "examination.file"
    }
  }) : _c('file-dialog', {
    attrs: {
      "filename": _vm.examination._filename
    },
    model: {
      value: _vm.examination,
      callback: function ($$v) {
        _vm.examination = $$v;
      },
      expression: "examination"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }