<template>
  <div class="cadre-position-label">
    <slot :label="label">
      {{ label || '-' }}
    </slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['id'],
  computed: {
    ...mapGetters({
      position: 'cadre/positions/byId',
    }),
    label() {
      return this.position(this.id)?.name;
    },
  },

  created() {
    if (!this.id) return;
    this.$store.dispatch('cadre/positions/fetch');
  },
};
</script>

<style lang="sass" scoped>
.prefix
  font-weight: 600
</style>
