<template>
  <!-- TODO: i18n jak już się upewnimy że ten komponent ma sens -->
  <b-tabs pills>
    <b-tab
      title="Zachowaj obecny"
      @click="updateValue('keep')"
      active
    >
      <b-card-text>
        Zachowaj obecny załącznik ({{ filename }}) bez zmian.
      </b-card-text>
    </b-tab>
    <b-tab
      title="Zmień"
      @click="updateValue('change')"
      >
      <b-card-text>
        Wybierz plik używając poniższego przycisku.
        Wybrany plik zastąpi istniejący załącznik (jeżeli istnieje)

        <vs-file
          v-model="localFile"
          class="mt-1"
        />
      </b-card-text>
    </b-tab>
    <b-tab
      title="Usuń"
      @click="updateValue('delete')"
      >
      <b-card-text>
        <state-badge
          text="Załączony plik zostanie na stałe usunięty z aplikacji"
        />
      </b-card-text>
    </b-tab>
  </b-tabs>
</template>

<script>
import { ref, watch } from 'vue';
import StateBadge from '@/components/ui/StateBadge.vue';
import {
  BTab, BTabs, BCardText,
} from 'bootstrap-vue';
import VsFile from '../vs-file/VsFile.vue';

export default {
  setup(props, { emit }) {
    const localFile = ref(null);

    const updateValue = (option) => {
      switch (option) {
        case 'keep':
          emit('input', {
            ...props.value,
            file: null,
            delete_file: false,
          });
          break;
        case 'change':
          emit('input', {
            ...props.value,
            file: localFile.value,
            delete_file: localFile.value ? null : false,
          });
          break;
        case 'delete':
          emit('input', {
            ...props.value,
            file: null,
            delete_file: true,
          });
          break;
        default:
          emit('input', 'default');
          break;
      }
    };

    updateValue('keep');
    watch(localFile, () => updateValue('change'));

    return { localFile, updateValue };
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    filename: {
      type: String,
      default: 'o ile istnieje',
    },
  },
  data: () => ({ training: { file: null } }),
  components: {
    BTab,
    BTabs,
    StateBadge,
    BCardText,
    VsFile,
  },
};
</script>
