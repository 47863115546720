import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCadreApi from '@/hooks/cadre/useCadreApi';

const { employee } = useCadreApi();

export default defineStore('cadreEmployee', () => {
  const { state, fetch, fetched } = useRemoteResource('cadre.employee', employee.fetchPicker);

  return {
    state,
    fetch,
    fetched,
  };
});
