var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cadre-position-label"
  }, [_vm._t("default", function () {
    return [_vm._v(" " + _vm._s(_vm.label || '-') + " ")];
  }, {
    "label": _vm.label
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }