var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    staticClass: "cadre-employee-label",
    class: {
      'deleted': !!_vm.employee.deleted_at
    },
    attrs: {
      "to": {
        name: 'cadre.employee.details',
        params: {
          id: _vm.employee.id
        }
      },
      "target": _vm.target
    }
  }, [_c('div', {
    staticClass: "select-option d-flex align-items-center"
  }, [_vm.nameOnly || _vm.withAvatar ? _c('avatar', {
    attrs: {
      "avatarDeleteButton": _vm.removable,
      "displayUserIcon": !!_vm.employee.user_core_id,
      "user": _vm.employee,
      "deleted": !!_vm.employee.deleted_at
    },
    on: {
      "delete": function ($event) {
        return _vm.$emit('delete');
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "details"
  }, [_c('div', {
    staticClass: "name font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.employee.forename || '') + " " + _vm._s(_vm.employee.surname || '') + " ")]), !_vm.nameOnly && !_vm.noProfession || _vm.noProfession && this.$slots['subtitle'] ? _c('div', {
    staticClass: "proffession"
  }, [_vm._t("subtitle", function () {
    return [_vm._v(" " + _vm._s(_vm.employee.email) + " ")];
  })], 2) : _vm._e()])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }